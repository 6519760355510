<template>
    <div class="home fullpage-container" :class="!isMobile ? 'pc-style' : '' ">
        <full-page :options="options" id="fullpage_" ref="fullpage_">
            <div class="banner banner-1 section">
                <div class="swiper-container swiper-banner-1" >
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="img" :class="{'show' : bannerIndex == 1}" :style="'background-image: url(' + require('../static/images/index/jpg_01.jpg') + ')'"></div>
                        </div>
                        <div class="swiper-slide">
                            <div class="img" :class="{'show' : bannerIndex == 2}" :style="'background-image: url(' + require('../static/images/index/jpg_01_2.jpg') + ')'"></div>
<!--                            <div class="bg">-->
<!--                                <div>-->
<!--                                    <p>“共”收益“富”生活</p>-->
<!--                                    <p>合作共建电站 共享电站收益</p>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
<!--                        <div class="swiper-slide">-->
<!--                            <div class="img" :class="{'show' : bannerIndex == 3}" :style="'background-image: url(' + require('../static/images/index/jpg_01_3.jpg') + ')'"></div>-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <div class="img" :class="{'show' : bannerIndex == 4}" :style="'background-image: url(' + require('../static/images/index/jpg_01_4.jpg') + ')'"></div>-->
<!--                        </div>-->
                    </div>
                    <div class="swiper-pagination"></div>
                    <div class="swiper-scrollbar"></div>
                </div>
                <div class="bg">
                    <div>
                        <p class="animate__animated animate__fadeInUp">“共”收益“富”生活</p>
                        <p class="animate__animated animate__fadeInUp animate__delay-01s">合作共建电站 共享电站收益</p>
                    </div>
                </div>
            </div>
            <div  class="banner banner-2 section">
                <div class="swiper-container swiper-banner-2" >
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="img" :style="'background-image: url(' + require('../static/images/index/jpg_02.jpg') + ')'"></div>
                        </div>
                        <div class="swiper-slide">
                            <div class="img" :style="'background-image: url(' + require('../static/images/index/jpg_03.jpg') + ')'"></div>
                        </div>
                        <div class="swiper-slide">
                            <div class="img" :style="'background-image: url(' + require('../static/images/index/jpg_04.jpg') + ')'"></div>
                        </div>
                    </div>
                </div>
                <div class="bg">
                    <div>
                        <ul>
                            <li id="banner2-li-1" :style="showIndex == 1 ? 'background-color: transparent' : ''" @click="banner2Click(1)">
                                <img src="../static/images/index/icon_01.png">
                                <p class="title">户用分布式</p>
                                <p class="content">User distributed</p>
                            </li>
                            <li id="banner2-li-2" :style="showIndex == 2 ? 'background-color: transparent' : ''" @click="banner2Click(2)">
                                <img src="../static/images/index/icon_02.png">
                                <p class="title">地面集中式</p>
                                <p class="content">Ground distributed</p>
                            </li>
                            <li id="banner2-li-3" :style="showIndex == 3 ? 'background-color: transparent' : ''" @click="banner2Click(3)">
                                <img src="../static/images/index/icon_03.png">
                                <p class="title">工商业分布式</p>
                                <p class="content">Industrial and commercial distributed</p>
                            </li>
                            <li id="banner2-active" class="active activezindex" :style="{'top': banner2ActiveTop + 'px','padding-top': showIndex == 1 ? '74px' : '0px'}" :class="showIndex == 1 ? 'act-top-0' : (showIndex == 2 ? 'act-top-30' : 'act-top-60')"></li>
                        </ul>
                        <div v-show="showIndex == 1" class="img-title animate__animated animate__fadeInRight">
                            <div class="title ">户用分布式</div>
                            <div class="sub-title">农户提供屋顶资源，公司出资建设、运营电站，农户获得固定电站收益。</div>
                        </div>
                        <div v-show="showIndex == 2" class="img-title animate__animated animate__fadeInRight">
                            <div class="title">地面集中式</div>
                            <div class="sub-title">使用未利用土地资源，建设集大型地面光伏电站。提高土地收益，带动地方产业。</div>
                        </div>
                        <div v-show="showIndex == 3" class="img-title animate__animated animate__fadeInRight">
                            <div class="title">工商业分布式</div>
                            <div class="sub-title">利用企业屋顶及厂区内闲置空地，由公司投建电站，企业享受租金收益或电费折扣。</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner banner-3 section">
                <div class="img" :style="'background-image: url(' + require('../static/images/index/jpg_05.jpg') + ')'"></div>
                <div class="bg"  @click="$router.push({path: '/about'})">
                    <div v-show="pageNum == 2 || isMobile"  class="animate__animated animate__fadeInUp animate__delay-01s">
                        <p class="title">关于帝森共富宝</p>
                        <p class="content">根据《国家能源局综合司关于报送整县屋顶分布式光伏开发试点方案的通知》指示，推广清洁能源的发展，增加居民收入，为实现“碳达峰、碳中和”的目标，帝森克罗德新能源推出了《共富宝项目》</p>
                        <p class="more" @mouseover="selectMore = true" @mouseout="selectMore = false"><a href="/about"><span :style="selectMore == true ? 'color:#ED2121;' : ''">查看详情</span><img :src="selectMore == true ? require('../static/images/index/icon_04.png') : require('../static/images/index/icon_04_select.png')"></a></p>
                    </div>
                </div>
            </div>
            <div class="banner banner-4 section">
                <div class="img" :style="'background-image: url(' + require('../static/images/index/jpg_06.jpg') + ')'"></div>
                <div class="bg" v-show="pageNum == 3 || isMobile" >
                    <div>
                        <p class="title animate__animated animate__fadeInUp">联系我们</p>
                        <div class="content">
                            <ul>
                                <li>
                                    <img class="animate__animated animate__fadeInLeft" src="../static/images/index/icon_dizhi.png"/>
                                    <div class="animate__animated animate__fadeInRight">
                                        <p>地址</p>
                                        <p>山东省济南市历城区经十东路28666号超算中心科技园2号楼</p>
                                    </div>
                                    <p class="line"><span></span></p>
                                </li>
                                <li>
                                    <img class="animate__animated animate__fadeInLeft"  src="../static/images/index/icon_dianhua.png"/>
                                    <div class="animate__animated animate__fadeInRight">
                                        <p>电话</p>
                                        <p>400-6767-658</p>
                                    </div>
                                    <p class="line"><span></span><span class="last"></span></p>
                                </li>
                                <li>
                                    <img class="animate__animated animate__fadeInLeft"  src="../static/images/index/icon_youxiang.png"/>
                                    <div class="animate__animated animate__fadeInRight">
                                        <p>E-MAIL</p>
                                        <p>newenergy@tysen-kld.com</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <foot></foot>
            </div>
        </full-page>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/css/swiper.min.css'
    import foot from "../components/foot";

    import $ from 'jquery'

    export default {
        name: "home",
        components: {foot},
        data(){
            return {
                showIndex: 1,
                bannerIndex: 0,
                nowScrollTop: 0,
                banner2ActiveTop: 0,
                selectMore: false,
                isV: true,
                currentPage: 1,
                pageNum: 0,
                options: {
                    licenseKey: "",
                    //是否显示导航，默认为false
                    navigation: true,
                    autoScrolling: true,
                    continuousVertical:true,
                    scrollOverflow: true,
                    // scrollingSpeed: 2000,
                    // paddingTop: '74px',
                    scrollBar: true,
                    loopTop: false,
                    loopBottom: false,
                    afterLoad: (anchorLink, index, slideAnchor, slideIndex) => {
                        this.afterSlideLoad(anchorLink, index, slideAnchor, slideIndex)

                        // if (index.index == 1) {
                        //     document.querySelectorAll(".section")[index.index].style.paddingTop = "74px"
                        // }
                    },
                    onLeave(){
                        console.log(1)
                    },
                    afterRender(){
                        console.log(2)
                    },
                    afterResize(){
                        console.log(3)
                    },
                    afterResponsive(){
                        console.log(4)
                    },
                    afterSlideLoad(){
                        console.log(5)
                    },
                    onSlideLeave(){
                        console.log(6)
                    }
                },
                swiper2: null,
                isMobile: false
            }
        },
        mounted() {
            this.$bus.$emit("headerFixed", true)

           let that = this
           new Swiper('.swiper-banner-1',{
                pagination:{
                    el: '.swiper-pagination',
                    bulletElement : 'li',
                    clickable: true
                },
                loop:true,
                speed:500,
                autoplay:{
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observer: true,
                observeParents: true,
                on: {
                    slideChange: function () {
                        if (this.activeIndex == 5) {
                            that.bannerIndex = 1
                        } else {
                            that.bannerIndex = this.activeIndex
                        }
                    }
                }
            })

            this.swiper2 = new Swiper('.swiper-banner-2',{
                pagination: false,
                loop:true,
                speed:500,
                autoplay:{
                    delay: 6000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observer: true,
                observeParents: true
            })

            this.swiper2.on('slideChange', function () {
                that.showIndex = Number(this.activeIndex)
                if (that.showIndex == 4) {
                    that.showIndex = 1
                }
                that.banner2Click(that.showIndex)
            })

            let ua = navigator.userAgent;
            let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
                isIphone =!ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
                isAndroid = ua.match(/(Android)\s+([\d.]+)/),
                isMobile = isIphone || isAndroid;

            this.isMobile = isMobile
            if (!isMobile) {
                this.$refs.fullpage_.init()
            } else {
                this.$refs.fullpage_.destroy()
            }




            window.addEventListener('scroll', this.scrollHandle)
        },
        methods: {
            afterSlideLoad(anchorLink, index, slideAnchor, slideIndex){
                console.log(anchorLink, index, slideAnchor, slideIndex)
                this.pageNum = index.index

            },
            scrollHandle(e){
                let top = e.srcElement.scrollingElement.scrollTop
                this.nowScrollTop = top
            },
            banner2Click(index){
                this.showIndex = index
                $("#banner2-active").stop().animate({top: $("#banner2-li-" + index).position().top + "px"},600);

                this.swiper2.slideTo(index, 1000, false)
            }
        }
    }
</script>

<style lang="less" scoped>

    .home {

        &.pc-style {
            .banner-2 {
                .img {
                    height: 100vh !important;
                    background-size: 100% 100%;
                }
            }

            .banner-3 {
                .img {
                    height: 100vh !important;
                    background-size: 100% 100%;
                }
            }

            .banner-4 {
                .img {
                    background-size: 100% 100%;
                }
            }
        }


        .banner {
            width: 100%;
            font-size: 0px;
            position: relative;

            .bg {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 1;
            }

            &.banner-1 {
                width: 100%;
                height: 100%;
                overflow: hidden;


                .swiper-banner-1 {
                    height: 100%;
                }

                .img {
                    min-height: 869px;
                    height: 100%;
                    width: 100%;
                    background-size: 100% 100%;
                }


                .img.show {
                    animation-name: changeBiger;
                    animation-duration:50s;
                    animation-timing-function:linear;
                    animation-fill-mode:forwards;
                }

                .bg {
                    pointer-events: none;

                    div {
                        margin-top: 14%;
                        margin-left: 19%;

                        p {
                            font-family: PingFang SC;
                            color: #FFFFFF;

                            &:first-child {
                                font-size: 75px;
                                letter-spacing: 9px;
                                font-weight: 100;
                            }

                            &:last-child {
                                margin-top: 8px;
                                font-size: 73px;
                                font-weight: bold;
                            }
                        }
                    }
                }


            }

            &.banner-2 {
                height: 100%;

                .img {
                    min-height: 960px;
                    height: 100%;
                    width: 100%;
                }

                .bg {
                    width: 100%;
                    /*margin-top: 74px;*/

                    > div {
                        height: calc(100% - 74px);
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;

                        ul {
                            height: 100%;
                            margin-left: 18.7%;
                            width: 227px;
                            position: relative;

                            li:first-child {
                                padding-top: 74px;
                            }

                            li {
                                cursor: pointer;
                                width: 100%;
                                list-style: none;
                                height: 33.3%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                background-color: rgba(0, 0, 0, 0.6);
                                color: #FFFFFF;
                                z-index: 1;

                                &#banner2-active {
                                    z-index: 1;
                                }

                                &.activezindex {
                                    z-index: 2 !important;
                                }

                                &.active {
                                    background: #C0102B;
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;

                                }

                                img {
                                    height: 31px;
                                    width: 31px;
                                    margin-bottom: 34px;
                                    z-index: 4;
                                }

                                .title {
                                    font-size: 18px;
                                    z-index: 4;
                                }

                                .content {
                                    font-size: 10px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 300;
                                    color: #FFFFFF;
                                    opacity: 0.5;
                                    transform: scale(0.8);
                                    z-index: 4;
                                }
                            }
                        }

                        .img-title {
                            margin-bottom: 90px;
                            div {
                                width: 100%;

                                padding-left: 90px;

                                &.title {
                                    font-size: 50px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    margin-bottom: 30px;
                                }

                                &.sub-title {
                                    font-size: 15px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
            }

            &.banner-3 {

                height: 100%;

                .img {
                    min-height: 960px;
                    height: 100%;
                    width: 100%;
                }

                .bg {
                    cursor: pointer;

                    > div {
                        margin-top: 10.8%;
                        margin-left: 18.5%;

                        .title {
                            font-size: 52px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                        }

                        .content {
                            font-size: 15px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                            width: 40%;
                            margin-top: 30px;
                            line-height: 25px;
                        }

                        .more {
                            margin-top: 100px;
                            display: flex;
                            flex-direction: row;
                            position: relative;
                            width: 85px;
                            cursor: pointer;

                            a {
                                text-decoration: none;
                            }

                            span {
                                font-size: 13px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                            }

                            img {
                                width: 25px;
                                height: 23px;
                                position: absolute;
                                bottom: -6px;
                                right: 0px;
                            }
                        }
                    }
                }
            }

            &.banner-4 {

                height: 100%;

                .img {
                    min-height: 960px;
                    height: 100%;
                    width: 100%;
                }


                .bg {
                    height: calc(100% - 354px);

                    > div {

                        .title {
                            font-size: 44px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-top: 7.8%;
                            margin-left: 18.6%;
                        }

                        .content {
                            margin-left: 29%;
                            margin-top: 2.5%;

                            ul {
                                li {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    padding: 40px 0px;
                                    position: relative;

                                    div {
                                        position: relative;
                                        top: 13px;
                                        p {

                                            &:first-child {
                                                font-size: 16px;
                                                font-family: PingFang SC;
                                                font-weight: 400;
                                                color: #FFFFFF;
                                            }

                                            &:last-child {
                                                font-size: 10px;
                                                font-family: PingFang SC;
                                                font-weight: 400;
                                                color: #FFFFFF;
                                                line-height: 20px;
                                            }
                                        }
                                    }

                                    .line {
                                        position: absolute;
                                        left: 75px;
                                        top: 50%;
                                        width: 1px;
                                        height: 100%;
                                        border-right: 1px dashed rgba(255, 255, 255, 0.2);

                                        span {
                                            position: absolute;
                                            top: 0px;
                                            left: 0px;
                                            width: 3px;
                                            height: 3px;
                                            border-radius: 50%;
                                            background-color: #FFFFFF;

                                            &.last {
                                                top: unset;
                                                bottom: 0px !important;
                                            }
                                        }
                                    }

                                    img {
                                        width: 28px;
                                        height: 28px;
                                        opacity: 0.4;
                                        margin-right: 100px;
                                    }

                                }

                            }
                        }
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }

    @keyframes changeBiger {
        0% {
            transform: scale(1.3);
        }

        100% {
            transform: scale(1);

        }
    }

    @keyframes opac11s {
        0%{
            opacity:0;
        }

        50%{
            opacity:0.5;
        }

        100%{
            opacity:1;
        }
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 20px !important;
    }

    .animate__delay-01s {
        animation-delay: 0.1s;
    }

    .animate__delay-03s {
        animation-delay: 0.3s;
    }
    @keyframes opac01s {
        0%{
            opacity:0;
        }

        50%{
            opacity:0.5;
        }

        100%{
            opacity:1;
        }
    }

    @keyframes blink {
        0%{
            opacity:0;
        }

        50%{
            opacity:0.5;
        }

        100%{
            opacity:1;
        }
    }

    @keyframes topbottom-0 {
        100% {
            top: 0px;
            /*transform: translateY(100%);*/
        }
    }

    @keyframes topbottom-30 {

        100% {
            /*transform: translateY(100%);*/
            top: 33.3%;
        }
    }

    @keyframes topbottom-60 {
        100% {
            /*transform: translateY(100%);*/
            top: 66.6%;
        }
    }
</style>

<style>
    .swiper-banner-1 .swiper-pagination-bullet {
        background: #FFFFFF !important;
        opacity: 1;
        width: 8px;
        height: 8px;
    }

    .swiper-banner-1 .swiper-pagination-bullet-active {
        background: #C20825 !important;
        width: 8px;
        height: 8px;
    }

    #fp-nav ul li a span {
        background-color: #C20825 !important;
    }

</style>
