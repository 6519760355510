<template>
  <div>
    <vue-header></vue-header>
    <router-view/>
  </div>
</template>

<script>
  import vueHeader from "./components/header";


  export default {
    name: "App",
    components: {vueHeader}
  }
</script>

<style>
  @import "assets/css/common.css";

  .fp-tableCell {
    display: block !important;
  }
</style>
