import Vue from 'vue'
import App from './App.vue'
import router from './route'

import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

import 'fullpage.js/vendors/scrolloverflow'
import VueFullpage from 'vue-fullpage.js'

import 'fullpage.js/dist/fullpage.css'

Vue.use(VueFullpage)

import 'animate.css'

Vue.prototype.$bus = new Vue()

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
