import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Home from '../views/Home.vue'

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: () => import( '../views/About.vue')
    },
    {
      path: '/business',
      name: 'BusinessArea',
      component: () => import( '../views/BusinessArea.vue')
    },
    {
      path: '/join',
      name: 'Join',
      component: () => import( '../views/Join.vue')
    },
    {
      path: '/recruit',
      name: 'Recruit',
      component: () => import( '../views/Recruit.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import( '../views/Contact.vue')
    }
  ]
})
