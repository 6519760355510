<template>
    <div class="header-main" v-show="isheaderShow" :style="isFixed ? 'position: fixed;left: 0px;top: 0px;z-index: 10;': ''">
        <div class="top-progress-bar"></div>
        <div class="top-bar">
            <a href="https://www.ds-gongfubao.com/">
                <div class="logo">
                    <img class="logo-1" src="@/static/images/logo-jt.png">
                    <p/>
                    <img class="logo-2" src="@/static/images/logo-cp.png">
                </div>
            </a>
            <div class="menu-bar">
                <ul>
                    <li v-for="(menu, index) in menuList" :key="index">
                        <router-link :class="{'router-link-exact-active router-link-active' : nowPath == menu.url}" :to="menu.url">{{menu.name}}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "vue-header",
        data(){
            return {
                isFixed: false,
                isheaderShow: true,
                menuList: [
                    {
                        name: '首页',
                        url: '/'
                    },
                    {
                        name: '关于我们',
                        url: '/about'
                    },
                    {
                        name: '业务领域',
                        url: '/business'
                    },
                    {
                        name: '招商加盟',
                        url: '/join'
                    },
                    {
                        name: '人才招聘',
                        url: '/recruit'
                    },
                    {
                        name: '联系我们',
                        url: '/contact'
                    }
                ],
                nowPath:""
            }
        },
        watch: {
            $route(){
                if (this.$route.query.i) {
                    this.selectIndex = this.$route.query.i
                }
                this.nowPath = this.$route.path
            }
        },
        mounted() {
            this.$bus.$on("headerFixed", content => {
                this.isFixed = content
            })

            this.$bus.$on("headerShow", content => {
                this.isheaderShow = content
            })
        }
    }
</script>

<style lang="less" scoped>

    .header-main {
        width: 100%;


        .top-progress-bar {
            width: 100%;
            height: 4px;
            background: #DB2340;
        }

        .top-bar {
            height: 74px;
            width: 100%;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 48px;

                .logo-1 {
                    height: 27px;
                }

                p {
                    width: 1px;
                    height: 19px;
                    background: #0B0B0B;
                    opacity: 0.11;
                    margin: 0px 13px;
                }

                .logo-2 {
                    height: 30px;
                }
            }

            .menu-bar {
                height: 100%;
                padding-right: 46px;
                ul {
                    display: flex;
                    flex-direction: row;
                    height: 100%;

                    li {
                        list-style: none;
                        height: 100%;
                        a {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 0px 18px;
                            height: 100%;
                            color: #333333;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            text-decoration: none;
                            margin: 0px 10px;

                            &.router-link-exact-active {
                                background: #DB2340;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
