<template>
    <div class="foot-main">
        <div class="foot-content">
            <ul>
                <li>
                    <ul>
                        <li>关于我们</li>
                        <li><router-link :to="{name: 'About', query: {i: 2}}">关于帝森共富宝</router-link></li>
                        <li><router-link :to="{name: 'About', query: {i: 2}}">帝森克罗德新能源</router-link></li>
                        <li><router-link :to="{name: 'About', query: {i: 3}}">济南历城控股集团</router-link></li>
                        <li><router-link :to="{name: 'About', query: {i: 4}}">帝森克罗德集团</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>业务领域</li>
                        <li><router-link :to="{name: 'BusinessArea', query: {i: 0}}">户用分布式</router-link></li>
                        <li><router-link :to="{name: 'BusinessArea', query: {i: 1}}">工商业分布式</router-link></li>
                        <li><router-link :to="{name: 'BusinessArea', query: {i: 2}}">地面集中式</router-link></li>
                        <li><router-link :to="{name: 'BusinessArea', query: {i: 3}}">充电桩</router-link></li>
                        <li><router-link :to="{name: 'BusinessArea', query: {i: 4}}">储能</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>招商加盟</li>
                        <li><router-link :to="{name: 'Join'}">户用电站合作伙伴</router-link></li>
                        <li><router-link :to="{name: 'Join'}">工商业合作伙伴</router-link></li>
                        <li><router-link :to="{name: 'Join'}">地面合作伙伴</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>人才招聘</li>
                        <li><router-link :to="{name: 'Recruit'}">商务部主管</router-link></li>
                        <li><router-link :to="{name: 'Recruit'}">商务人员</router-link></li>
                        <li><router-link  :to="{name: 'Recruit'}">设计验收主管</router-link></li>
                        <li><router-link  :to="{name: 'Recruit'}">设计审核人员</router-link></li>
                        <li><router-link  :to="{name: 'Recruit'}">验收人员</router-link></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>联系我们</li>
                        <li><router-link :to="{name: 'Contact'}">公司地址</router-link></li>
                        <li><router-link  :to="{name: 'Contact'}">联系方式</router-link></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="foot-contact">
            <ul>
                <li>
                    <img src="../static/images/foot/icon_dianhua01.png">
                    <p>电话：400-6767-658</p>
                </li>
                <li>
                    <img src="../static/images/foot/icon_dianhua02.png">
                    <p>邮箱：newenergy@tysen-kld.com</p>
                </li>
                <li>
                    <img src="../static/images/foot/icon_dianhua03.png">
                    <p>地址：山东省济南市历城区经十东路 28666号超算中心科技园2号楼</p>
                </li>
            </ul>
        </div>
        <div class="foot-copyright">
            <p>版权所有：copyright © www.ds-gongfubao.com 帝森克罗德(山东)新能源有限公司</p>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010102001255">
                <img src="../static/images/foot/foot_icon.png">
                <p>鲁公网安备37010102001255</p>
            </a>
            <p><a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2021044358号-3</a></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "foot"
    }
</script>

<style lang="less" scoped>
    .foot-main {
        width: 100%;
        background: #FBFBFB;

        .foot-content {
            padding-left: 18%;
            padding-top: 35px;
            padding-bottom: 60px;

            > ul {
                display: flex;
                flex-direction: row;

                > li {
                    list-style: none;
                    margin-right: 100px;
                    > ul {
                        li {
                            list-style: none;

                            a {
                                text-decoration: none;
                                color: #888888;
                            }


                            a:hover {
                                color: #C0102B;
                            }

                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #888888;
                            line-height: 26px;

                            &:first-child {
                                font-size: 14px;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                color: #666666;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        .foot-contact {
            width: calc(100% - 18%);
            height: 45px;
            border-top: 1px solid #F0F0F0;
            padding-left: 18%;
            display: flex;
            flex-direction: row;
            align-items: center;

            ul {
                display: flex;
                flex-direction: row;

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #B3B3B3;
                    margin-right: 20px;

                    img {
                        margin-right: 5px;
                    }
                }
            }
        }

        .foot-copyright {
            padding-left: 18%;
            width: calc(100% - 18%);
            height: 46px;
            background: rgba(236,236,236, 0.6);

            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #B3B3B3;
            }

            a {
                text-decoration: none;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #B3B3B3;
                margin-left: 10px;
                display: flex;
                flex-direction: row;
            }




            img {
                /*width: 18px;*/
                height: 16px;
                margin-left: 16px;
                margin-right: 4px;
            }
        }
    }
</style>
